:root {
    --max-width: 1400px;
    --break-width: 900px;

    /* color palette */
    --primary-color-1: var(--peachy-pink);

    /* buttons */
    --button-primary-bg-color: var(--primary-color-1);
    --button-primary-font-color: white;

    --button-secondary-bg-color: white;
    --button-secondary-font-color: var(--primary-color-1);

    --button-light-bg-color: var(--peachy-pink-pale);
    --button-light-font-color: white;

    --button-dark-bg-color: var(--peachy-purple);
    --button-dark-font-color: white;

    /* links */
    --link-color: var(--primary-color-1);

    /* forms */
    --form-input-bg-color: white;
    --form-highlight: var(--primary-color-1);
    --form-success: limegreen;
    --form-error: var(--peachy-pink);
    --form-hover: mistyrose;
    --form-selected: var(--primary-color-1);

    /* other */
    --header-color: white;
    --header-height: 4em;
    --fullscreen-with-header-height: calc(100vh - var(--header-height));
    --surface-color: white; /* the main surface colour, e.g. for cards, modals etc */
}


.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: var(--fullscreen-with-header-height);
}

form .toggle-options {
    flex-direction: column;
    gap: 1em;
}

form .toggle-options label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5em;
}

form .toggle-options label,
form p {
    font-size: var(--font-size-small);
}

/* === FONT AWESOME ICONS === */

.icon-info::before {
    content: '\f05a';
    font: var(--fa-font-regular);
}

.icon-calendar::before {
    content: '\f133';
    font: var(--fa-font-light);
}

.icon-warning::before {
    content: '\f071';
    font: var(--fa-font-regular);
}

.icon-edit::before {
    content :'\f044';
    font: var(--fa-font-regular);
}

.icon-search::before {
    content :'\f002';
    font: var(--fa-font-regular);
}

/* todo: this has been copied from web-dashboard peachy-library.module.css */
.clickable:hover {
    cursor: pointer;
}

.blur {
    background-color: #FFFFFF77;
    animation: blurAnimation 200ms ease-out forwards;
}

@keyframes blurAnimation {
    0% {
        backdrop-filter: blur(0);
    }
    100% {
        backdrop-filter: blur(0.1em);
    }
}